<template>

    <div class="footer-desktop__wrapper has-bottom">
        <div class="footer-desktop__desc-wrapper ">
            <div class="container display-flex-sb" style="align-items: baseline;">
                <div class="footer-desktop__desc-container" v-if="currentLocale != 'vn'">
                    <h2 class="footer-desktop__desc-title" v-if="currentLocale === 'th'">{{$t('FOOTER_DESCRIPTION_TITLE')}}</h2>
                     <h2 class="footer-desktop__desc-title" v-else></h2>
                    <p class="footer-desktop__desc-content">{{$t('FOOTER_DESCRIPTION_CONTENT')}}</p>
                </div>  
                <div class="footer-desktop__desc-container" v-if="!isMobile">
                    <h2 class="footer-desktop__desc-title" >{{$t('FOOTER_DISCLAIMER_TITLE')}}</h2>
                    <p class="footer-desktop__desc-content">{{$t('FOOTER_DISCLAIMER_CONTENT')}}</p>
                </div>        
            </div>
        </div>
        <div class="footer-mobile__desc-wrapper " v-if="isMobile">
            <div class="container display-flex-sb" style="align-items: baseline;"> 
                <div class="footer-mobile__desc-container">
                    <h2 class="footer-desktop__desc-title" >{{$t('FOOTER_DISCLAIMER_TITLE')}}</h2>
                    <p class="footer-desktop__desc-content">{{$t('FOOTER_DISCLAIMER_CONTENT')}}</p>
                </div>        
            </div>
        </div>
        <div v-if="isMobile" class="footer-mobile__desc-wrapper footer-mobile__privacy-wrapper">
            <div class="footer-mobile__privacy">
                <router-link :to="{name: 'privacypolicy'}" >{{$t('PRIVACY_POLICY')}}</router-link>
            </div>
            <p class="footer-mobile__copyright">{{$t('COPYRIGHT')}} © {{currentYear}} www.asiasport.com</p>
        </div>
        <div class="footer-desktop__social-wrapper ">
            <div class="container">
                <div class="display-flex-align-center">
                    <MainLogo class="mr-2rem home-logo"></MainLogo>
                    <div class="footer-desktop__social-container">
                        <a :href="$t('FB')" target="_blank" class="footer-desktop__social-icon hover-grow"><img src="../../../static/images/icons/icon_fb.png"></a>
                        <a :href="$t('IG')" target="_blank" class="footer-desktop__social-icon hover-grow"><img src="../../../static/images/icons/icon_ig.png"></a>
                        <a href="https://line.me/R/ti/p/@asiasport.thai" target="_blank" v-if="currentLocale === 'th'" class="footer-desktop__social-icon hover-grow"><img src="../../../static/images/icons/icon_line.png"></a>
                        <a href="mailto:support@asiasport.com" target="_blank" class="footer-desktop__social-icon hover-grow"><img src="../../../static/images/icons/icon_mail.png"></a>
                        <!-- <a :href="$t('TWITTER')" target="_blank" class="footer-desktop__social-icon"><img src="../../../static/images/icons/icon_twitter.png"></a> -->
                    </div>          
                    <div class="footer-desktop__copy-right">
                        <p class="font-m">{{$t('COPYRIGHT')}} © {{currentYear}} www.asiasport.com</p>
                    </div> 
                    <div>
                        <router-link :to="{name: 'privacypolicy'}" class="font-m footer-desktop__privacy">{{$t('PRIVACY_POLICY')}}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-wrapper">
        <ul class="footer-container">

            <router-link :to="{name: o.pathName}" class="footer-icon__wrapper" :class="{selected : o.selected}" v-for="(o, index) in footerTabs" :key="index">
                          
               <div class="relative">
                    <div :class="`footer-icon__container footer-icon__${index+1}`" ></div>
                        <span>{{$t(o.name)}}</span>
                    <div v-if="o.isLive" class="dot-wrapper">
                        <div class="ripple"></div>
                    </div>
                </div>
            </router-link>
            <!-- <div class="footer-icon__wrapper" @click="handleRefresh()">
                <div class="footer-icon__container footer-icon__5 " :class="{'footer-refresh':isRefreshing}"></div>
                <span>{{$t('REFRESH')}}</span>
            </div>  -->
            <!-- <router-link :to="`/${currentLocale}/highlights`" class="footer-icon__wrapper" >
                <div class="footer-icon__container footer-icon__2"></div>
                <span>Highlights</span>
            </router-link> -->
            <!-- <router-link :to="`/${currentLocale}/news`"  class="footer-icon__wrapper" >
                <div class="footer-icon__container footer-icon__3"></div>
                <span>News</span>
            </router-link>   
            <router-link to="/notification" class="footer-icon__wrapper">
                <div class="footer-icon__container footer-icon__4"></div>
                <span>Notifications</span>
            </router-link>   -->
            <!-- <div class="footer-icon__wrapper" @click="handleRefresh()">
                <div class="footer-icon__container footer-icon__5"></div>
                <span>Refresh</span>
            </div> -->
        </ul>
    </div> 

</template>

<script>
import {mapGetters,mapActions} from 'vuex'
import MainLogo from '@/components/indicator/MainLogo.vue'
export default {
    components:{
        MainLogo
    },
    computed:{
        ...mapGetters([              
            'currentLocale',
            'footerTabs',
            'isMobile'
        ]),
    },
    data() {
        return {
          isRefreshing: false,
      
          currentYear: new Date().getFullYear()
        }
    },
    mounted() {
      
    },
    methods: {
          ...mapActions([
            "refresh",
        ]),

        handleRefresh(){
            this.isRefreshing = true;
            setTimeout(() => this.isRefreshing  = false, 500);

            //this.$router.go()
            //location.reload();
            this.refresh()
        }
    },
}
</script>

<style>

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); }}
    .footer-refresh{
        -webkit-animation:spin 2s linear infinite;
        -moz-animation:spin 2s linear infinite;
        animation:spin .8s linear infinite;
    }
    .footer-desktop__desc-wrapper {
        background-color:rgb(17 22 24 / 50%);
        border-top:.1rem solid #35454D;
        border-bottom:.1rem solid #35454D
    }
    .footer-desktop__desc-container{
        width: 48.5%;
    }
    .footer-desktop__desc-title{
        font-size:.7rem;
        margin: 1rem 0;
        color : var(--color-grey-light)

    }
    .footer-desktop__desc-content{
        font-size:.6rem;
        color: var(--color-theme-2);
        margin-bottom: 1rem;
    }   
    .footer-desktop__social-wrapper{
        margin:1rem 0
    }
    .footer-desktop__social-container{
        display: flex;
        margin-right: 3rem;
    }
    .footer-desktop__copy-right{
        margin-right: 3.5rem;
        color:var(--color-theme-2)
    }
    .footer-desktop__social-icon{
        cursor: pointer;
        padding: 0 1rem;
        width: 1.2rem;
        height: 1.2rem;
    }
    .footer-desktop__social-icon img{
        width:100%;
        height: 100%;
        object-fit: contain;
    }
    .footer-desktop__wrapper{
        position: relative;
    }
    .footer-desktop__privacy:hover{
        text-decoration: underline;
    }
    .footer-wrapper{
        display: none;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        background-color: var(--bg-theme);
        border-top: .1rem solid #35454D;
        box-shadow: 0 0 7px rgba(0,0,0,.3);
    }
    .footer-container{
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        margin: 0.5rem 0 .8rem 0;
    }
    .footer-icon__wrapper{
        -webkit-flex: 1;
        flex: 1;
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -ms-flex: 1;
        color: gray;
        font-size: .7rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .footer-icon__container{
        display: block;
        margin: 0 auto ;
        width: 1.3rem;
        height: 1.3rem;
        transition: all.5s;
        margin-bottom: .2rem;

    }
    .footer-icon__wrapper.selected{
        color:var(--color-theme-light); 
    }
        
    .footer-icon__1{
        background: url(../../../static/images/icons/icon_footer_home_unselect.png) center/cover no-repeat;
        background-size: inherit;
    }
    .selected .footer-icon__1 {
        animation: nimateBall .5s ease forwards;
        -webkit-animation: nimateBall .5s ease forwards;
        background: url(../../../static/images/icons/icon_footer_home_selected.png) center/cover no-repeat;
    }
    .footer-icon__2{
        background: url(../../../static/images/icons/icon_footer_highlights_unselect.png) center/cover no-repeat;
        background-size: inherit;
    }
    .selected .footer-icon__2{
        animation: nimateBall .5s ease forwards;
        -webkit-animation: nimateBall .5s ease forwards;
        background: url(../../../static/images/icons/icon_footer_highlights_selected.png) center/cover no-repeat;
        background-size: inherit;
    }
    .footer-icon__3{
        background: url(../../../static/images/icons/icon_footer_news_unselect.png) center/cover no-repeat;
        background-size: inherit;
    }
    .selected  .footer-icon__3{
        animation: nimateBall .5s ease forwards;
        -webkit-animation: nimateBall .5s ease forwards;
        background: url(../../../static/images/icons/icon_footer_news_selected.png) center/cover no-repeat;
        background-size: inherit;
    }
     .footer-icon__4{
        background: url(../../../static/images/icons/icon_footer_event_unselect.png) center/cover no-repeat;
        background-size: inherit;
    }
    .selected  .footer-icon__4{
        animation: nimateBall .5s ease forwards;
        -webkit-animation: nimateBall .5s ease forwards;
        background: url(../../../static/images/icons/icon_footer_event_selected.png) center/cover no-repeat;
        background-size: inherit;
    }
    /* .footer-icon__4{
        background: url(../../../static/images/icons/icon_footer_notification_unselect.png) center/cover no-repeat;
        background-size: inherit;
    }
    .router-link-active  .footer-icon__4{
        animation: nimateBall .5s ease forwards;
        -webkit-animation: nimateBall .5s ease forwards;
        background: url(../../../static/images/icons/icon_footer_notification_unselect.png) center/cover no-repeat;
        background-size: inherit;
    } */
    .footer-icon__5{
        background: url(../../../static/images/icons/icon_footer_refresh.png) center/cover no-repeat;
        background-size: inherit;
    }
    .router-link-active  .footer-icon__5{
        animation: nimateBall .5s ease forwards;
        -webkit-animation: nimateBall .5s ease forwards;
        background: url(../../../static/images/icons/icon_footer_more_unselect.png) center/cover no-repeat;
        background-size: inherit;
    }
    .footer-icon__wrapper .dot-wrapper {
        top: -0.3rem;
        right: 0.3rem;
    }
    @media (max-width: 768px){
        /* .footer-desktop__wrapper {
            display: none;
        } */
        .footer-desktop__social-wrapper {
            display: none;
        }
        .footer-desktop__desc-container{
            width: auto;
            padding: 0 0.8rem 1.5rem 0.8rem;
        }
        .footer-desktop__desc-wrapper{
            border-bottom: none;
        }
        .footer-mobile__desc-wrapper{
            margin: 0 .8rem;
            border-top: .1rem solid #35454D;
        }
        .footer-mobile__desc-container{
            width: auto;
        }
        .footer-wrapper{
             display: block;
        }
        .footer-mobile__privacy{
            font-size: 0.6875rem;

            margin-bottom: .5rem;
        }
        .footer-mobile__privacy a {
            color:var(--bg-selected);
        }
        .footer-mobile__privacy-wrapper{
            padding: 1rem 0;
        }
        .footer-mobile__copyright{
            color: #5C7784;
            font-size: 0.6875rem;
        }
    }
</style>